<template>
  <div
    class="tab-item-container tab-item-container__block"
    :class="{ disabled: !hasInventory }"
  >
    <div class="tab-item-container__block-row body-1-sb grey-80">
      {{ $t('Settings.Inventory.lbMaximumOf') }}
    </div>
    <div class="tab-item-container__block-column">
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__block-column">
          <SlCheckbox
            v-model="inventoryParams.enableServiceLevel"
            :label="enableServiceLevelLabel"
            class="tab-item-container__label"
            @change="handleChange('inventoryParams', 'enableServiceLevel', $event)"
          />
          <div class="tab-item-container__formula tab-item-container__row-folded">
            <div class="tab-item-container__label">
              <div class="tab-item-container__label--filled">
                <span class="body-1 grey-80">
                  {{ $t('Settings.Inventory.CalculatedValueLabel') }}
                </span>
                {{ inventoryServiceLevelFormula }}
              </div>
            </div>
          </div>
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="inventoryParams.serviceLevel"
          rules="required|between:0.1,99.9"
        >
          <SlControlInput
            v-model="inventoryParams.serviceLevel"
            :is-invalid="invalid"
            :disabled="!inventoryParams.enableServiceLevel"
            :min="0.1"
            :max="serviceLevelMaxValue"
            :fixed="1"
            @change="handleChange('inventoryParams', 'serviceLevel', getServiceLevelValue($event))"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-column tab-item-container__column-folded">
        <SlCheckbox
          v-model="inventoryEnableFutureSalesModel"
          :indeterminate="isInventoryFutureSalesModelIndeterminate"
          :label="$t('WiInventorySettings.Ui.chkFutureDemand')"
          class="tab-item-container__label"
        />
        <div class="tab-item-container__block-column tab-item-container__column-folded tab-item-container__column-offset">
          <div class="tab-item-container__block-row">
            <SlCheckbox
              v-model="inventoryParams.futureSales.enabled"
              :label="salesDemandLabel"
              class="tab-item-container__label"
              @change="handleChange('inventoryParams', 'futureSales.enabled', $event)"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="inventoryParams.futureSales.val"
              rules="required|between:0,100"
            >
              <SlControlInput
                v-model="inventoryParams.futureSales.val"
                :is-invalid="invalid"
                :min="0"
                :max="100"
                :fixed="1"
                :step="0.1"
                :disabled="!inventoryParams.futureSales.enabled"
                @change="handleChange('inventoryParams', 'futureSales.val', $event || '0')"
              />
            </SlValidate>
          </div>
          <div class="tab-item-container__block-row">
            <SlCheckbox
              v-model="inventoryParams.futureDc.enabled"
              :label="lowerEchelonLabel"
              class="tab-item-container__label"
              @change="handleChange('inventoryParams', 'futureDc.enabled', $event)"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="inventoryFutureDcValueModel"
              rules="required|between:0,100"
            >
              <SlControlInput
                v-model="inventoryParams.futureDc.val"
                :is-invalid="invalid"
                :min="0"
                :max="100"
                :fixed="1"
                :step="0.1"
                :disabled="!inventoryParams.futureDc.enabled"
                @change="handleChange('inventoryParams', 'futureDc.val', $event || '0')"
              />
            </SlValidate>
          </div>
          <div
            v-if="hasBom"
            class="tab-item-container__block-row"
          >
            <SlCheckbox
              v-model="inventoryParams.futureBom.enabled"
              :label="materialConsumptionLabel"
              class="tab-item-container__label"
              @change="handleChange('inventoryParams', 'futureBom.enabled', $event)"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="inventoryParams.futureBom.val"
              rules="required|between:0,100"
            >
              <SlControlInput
                v-model="inventoryParams.futureBom.val"
                :is-invalid="invalid"
                :min="0"
                :max="100"
                :fixed="1"
                :step="0.1"
                :disabled="!inventoryParams.futureBom.enabled"
                @change="handleChange('inventoryParams', 'futureBom.val', $event || '0')"
              />
            </SlValidate>
          </div>
        </div>
      </div>
    </div>
    <div :class="`tab-item-container__block${!showColorOption || wantAlternativeForDCModel ? '-row' : ''}`">
      <SlCheckbox
        v-model="wantAlternativeForDCModel"
        :label="$t('WiInventorySettings.Ui.chkAlternateSafetyStockForDc')"
        :disabled="!hasDistribution"
        toggle
      />
    </div>
    <transition name="fade-down">
      <div
        v-if="wantAlternativeForDCModel && !isDcDisabled"
        class="tab-item-container__block"
      >
        <div class="tab-item-container__block-row">
          <span class="tab-item-container__block-title grey-90">
            {{ $t('WiInventorySettings.Ui.grpSafetyStockForDc') }}
          </span>
        </div>
        <div class="tab-item-container__block-row body-1-sb grey-80">
          {{ $t('Settings.Inventory.lbMaximumOf') }}
        </div>
        <div class="tab-item-container__block-column">
          <div class="tab-item-container__block-row">
            <div class="tab-item-container__block-column">
              <SlCheckbox
                v-model="dcParams.enableServiceLevel"
                :label="enableServiceLevelLabel"
                class="tab-item-container__label"
                @change="handleChange('dcParams', 'enableServiceLevel', $event)"
              />
            </div>
            <SlValidate
              v-slot="{ invalid }"
              vid="dcParams.serviceLevel"
              rules="required|between:0.1,99.9"
            >
              <SlControlInput
                v-model="dcParams.serviceLevel"
                :is-invalid="invalid"
                :disabled="!dcParams.enableServiceLevel"
                :min="0.1"
                :max="serviceLevelMaxValue"
                :fixed="1"
                @change="handleChange('dcParams', 'serviceLevel', getServiceLevelValue($event))"
              />
            </SlValidate>
          </div>
          <div class="tab-item-container__block-column tab-item-container__column-folded">
            <SlCheckbox
              v-model="dcEnableFutureSalesModel"
              :indeterminate="isDcFutureSalesModelIndeterminate"
              :label="$t('WiInventorySettings.Ui.chkFutureDemand')"
              class="tab-item-container__label"
            />
            <div class="tab-item-container__block-column tab-item-container__column-folded tab-item-container__column-offset">
              <div class="tab-item-container__block-row">
                <SlCheckbox
                  v-model="dcParams.futureSales.enabled"
                  :label="salesDemandLabel"
                  class="tab-item-container__label"
                  @change="handleChange('dcParams', 'futureSales.enabled', $event)"
                />
                <SlValidate
                  v-slot="{ invalid }"
                  vid="dcParams.futureSales.val"
                  rules="required|between:0,100"
                >
                  <SlControlInput
                    v-model="dcParams.futureSales.val"
                    :is-invalid="invalid"
                    :min="0"
                    :max="100"
                    :fixed="1"
                    :step="0.1"
                    :disabled="!dcParams.futureSales.enabled"
                    @change="handleChange('dcParams', 'futureSales.val', $event || '0')"
                  />
                </SlValidate>
              </div>
              <div class="tab-item-container__block-row">
                <SlCheckbox
                  v-model="dcParams.futureDc.enabled"
                  :label="lowerEchelonLabel"
                  class="tab-item-container__label"
                />
                <SlValidate
                  v-slot="{ invalid }"
                  vid="dcParams.futureDc.val"
                  rules="required|between:0,100"
                >
                  <SlControlInput
                    v-model="dcParams.futureDc.val"
                    :is-invalid="invalid"
                    :min="0"
                    :max="100"
                    :fixed="1"
                    :step="0.1"
                    :disabled="!dcParams.futureDc.enabled"
                    @change="handleChange('dcParams', 'futureDc.val', $event || '0')"
                  />
                </SlValidate>
              </div>
              <div
                v-if="hasBom"
                class="tab-item-container__block-row"
              >
                <SlCheckbox
                  v-model="dcParams.futureBom.enabled"
                  :label="materialConsumptionLabel"
                  class="tab-item-container__label"
                  @change="handleChange('dcParams', 'futureBom.enabled', $event)"
                />
                <SlValidate
                  v-slot="{ invalid }"
                  vid="dcParams.futureBom.val"
                  rules="required|between:0,100"
                >
                  <SlControlInput
                    v-model="dcParams.futureBom.val"
                    :is-invalid="invalid"
                    :min="0"
                    :max="100"
                    :fixed="1"
                    :step="0.1"
                    :disabled="!dcParams.futureBom.enabled"
                    @change="handleChange('dcParams', 'futureBom.val', $event || '0')"
                  />
                </SlValidate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="showColorOption"
      class="tab-item-container__block"
    >
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__block-title grey-90">
          {{ $t('Settings.Ui.grpColor') }}
        </span>
      </div>
      <div class="tab-item-container__block-row">
        <SlColorPicker
          v-model="colorModel"
          :options="colorPickerOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderStrategyKeys } from '@/config/shared/settings.config';
import { colors } from '@/config/shared/slColorPicker.config';
import { normalInverse } from '@/helpers/formula/normalInverse';

export default {
  name: 'SafetyStockForm',
  props: {
    settings: {
      type: Object,
      required: true
    },
    showColorOption: Boolean
  },
  data() {
    return {
      serviceLevelMaxValue: 99.9,
      colorPickerOptions: [
        colors.GREEN,
        colors.YELLOW,
        colors.RED,
        colors.BLUE,
        colors.PURPLE,
        colors.GREY
      ],
      orderStrategyKeys
    };
  },
  computed: {
    ...mapGetters({
      hasInventory: 'project/hasInv',
      hasDistribution: 'project/hasDistrib',
      hasBom: 'project/hasBom',
      period: 'project/period',
      inventoryOrderStrategy: 'settings/inventoryOrderStrategy'
    }),
    inventoryServiceLevelFormula() {
      const squareRootValue = this.inventoryOrderStrategy === orderStrategyKeys.MINMAX ? 1 : '';

      return this.$t(`Settings.Formula${squareRootValue}`, {
        1: this.inventoryNormalInverseValue
      });
    },
    inventoryNormalInverseValue() {
      return normalInverse(Math.max(+this.inventoryParams.serviceLevel, 50) / 100).toFixed(3);
    },
    enableServiceLevelLabel() {
      return `${this.$t('WiInventorySettings.Ui.chkServiceLevel')}, ${this.$t('WiInventorySettings.Ui.lbServiceLevelPc')}`;
    },
    salesDemandLabel() {
      return `${this.$t('Web.Settings.Inventory.salesDemandLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    lowerEchelonLabel() {
      return `${this.$t('Web.Settings.Inventory.lowerEchelonLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    materialConsumptionLabel() {
      return `${this.$t('Web.Settings.Inventory.materialConsumptionLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    inventoryParams() {
      return this.settings?.inventoryParams;
    },
    dcParams() {
      return this.settings?.dcParams;
    },
    inventoryEnableFutureSalesModel: {
      get() {
        return Object.values(this.allInventoryFutureSalesModels).some(model => model);
      },
      set(value) {
        Object.keys(this.allInventoryFutureSalesModels).forEach(modelKey =>
          this.handleChange('inventoryParams', modelKey, value)
        );
      }
    },
    wantAlternativeForDCModel: {
      get() {
        return this.isDcDisabled ? false : this.settings?.wantAlternativeForDC;
      },
      set(value) {
        this.$emit('change', {
          key: 'wantAlternativeForDC',
          value
        });
      }
    },
    dcEnableFutureSalesModel: {
      get() {
        return Object.values(this.allDcFutureSalesModels).some(model => model);
      },
      set(value) {
        Object.keys(this.allDcFutureSalesModels).forEach(modelKey =>
          this.handleChange('dcParams', modelKey, value)
        );
      }
    },
    colorModel: {
      get() {
        return this.settings?.color;
      },
      set(value) {
        this.$emit('change', {
          key: 'color',
          value
        });
      }
    },
    allInventoryFutureSalesModels() {
      return {
        'futureSales.enabled': this.inventoryParams.futureSales.enabled,
        'futureDc.enabled': this.inventoryParams.futureDc.enabled,
        ...(this.hasBom) && {
          'futureBom.enabled': this.inventoryParams.futureBom.enabled
        }
      };
    },
    isAllInventoryFutureSalesSelected() {
      return Object.values(this.allInventoryFutureSalesModels).every(model => model);
    },
    isInventoryFutureSalesModelIndeterminate() {
      const isAnyFutureSalesSelected  = Object.values(this.allInventoryFutureSalesModels).some(model => model);

      return isAnyFutureSalesSelected && !this.isAllInventoryFutureSalesSelected;
    },
    allDcFutureSalesModels() {
      return {
        'futureSales.enabled': this.dcParams.futureSales.enabled,
        'futureDc.enabled': this.dcParams.futureDc.enabled,
        ...(this.hasBom) && {
          'futureBom.enabled': this.dcParams.futureBom.enabled
        }
      };
    },
    isAllDcFutureSalesSelected() {
      return Object.values(this.allDcFutureSalesModels).every(model => model);
    },
    isDcFutureSalesModelIndeterminate() {
      const isAnyFutureSalesSelected  = Object.values(this.allDcFutureSalesModels).some(model => model);

      return isAnyFutureSalesSelected && !this.isAllDcFutureSalesSelected;
    },
    isDcDisabled() {
      return !this.hasInventory || !this.hasDistribution;
    }
  },
  methods: {
    getServiceLevelValue(value) {
      return +value > this.serviceLevelMaxValue
        ? this.serviceLevelMaxValue
        : +value;
    },
    handleChange(key, path, value) {
      this.$emit('change', {
        key,
        path,
        value
      });
    }
  }
};

</script>

<style lang="scss" scoped>
@import "@/style/components/settings";

.tab-item-container__block {
  margin-top: -24px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>