<template>
  <SlModal
    :id="id"
    :title="title"
    persistent
    @created="onCreated"
    @on-enter="handleApply"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <ValidationObserver ref="observer">
        <SafetyStockForm
          :settings="settings"
          show-color-option
          @change="handleFormChange"
        />
      </ValidationObserver>
    </div>
    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleApply">
          {{ $t('Web.Modals.BtnApply') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import SafetyStockForm from '@/components/Settings/SafetyStock/SafetyStockForm.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { setNestedValue } from '@/helpers/utils/setNestedValue';

export default {
  name: 'SafetyStockEditModal',
  components: {
    SafetyStockForm
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: '',
      settings: null,
      massEdit: false,
      applyCallback: null,
      modalsId
    };
  },
  methods: {
    onModalHide() {
      this.title = '';
      this.settings = null;
      this.massEdit = false;
      this.applyCallback = null;
    },
    handleFormChange({ key, path, value }) {
      if (path) {
        this.settings[key] = setNestedValue(this.settings[key], path, value);
      } else {
        this.settings[key] = value;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleApply() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      if (this.massEdit) {
        this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.SafetyStockEdit.TitleApply'),
          text: this.$t('Web.Modals.SafetyStockEdit.TextApply'),
          icon: 'style_warning_double',
          confirmText: this.$t('Web.Modals.BtnApply'),
          confirmCallback: () => {
            this.applySettings();
          }
        });
      } else {
        this.applySettings();
      }
    },
    applySettings() {
      this.applyCallback && this.applyCallback(this.settings);

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  overflow-x: hidden;
}

.tab-item-container__block {
  margin-top: 0;
}

::v-deep .sl-modal__header-title {
  white-space: normal;
}
</style>